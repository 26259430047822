











































import merge from "../utils/merge";
import DataContainer from "../common/DataContainer";
import defaultComponent from "../utils/defaultComponent";

export default defaultComponent.apply({
  props: ["xData", "xDisabled"],
  data() {
    return {
      container: new DataContainer(),
      formContainers: {},
      models: {},
      dirties: {},
      validatings: {},
      errors: {}
    };
  },
  methods: {
    initialize() {
      const self = this;
      const forms = this.options.ext.forms;
      for (const formName in forms) {
        this.formContainers[formName] = new DataContainer();
        this.models[formName] = new DataContainer(true, () => {
          self.updateValue("model", true);
        });
        this.dirties[formName] = new DataContainer(false, () => {
          self.updateValue("dirty", false);
        });
        this.validatings[formName] = new DataContainer(false, () => {
          self.updateValue("validating", false);
        });
        this.errors[formName] = new DataContainer(null, () => {
          self.updateValue("error", null);
        });

        forms[formName].slotName = `tabs.${formName}`;
        if (!this.options.content.items[formName]) {
          this.options.content.items[formName] = {};
        }
      }
    },
    updateValue(name, defaultValue) {
      let value = defaultValue;
      for (const formName of Object.keys(this.formContainers)) {
        const form = this.formContainers[formName].value;
        if (form && form[name]) {
          const formValue = form[name].value;
          if ((formValue && !defaultValue) || (!formValue && defaultValue)) {
            value = formValue;
            break;
          }
        }
      }
      this[name].value = value;
    },
    reset() {
      for (const formName of Object.keys(this.formContainers)) {
        const form = this.formContainers[formName].value;
        form.reset();
      }
    },
    getData() {
      let data = {};
      for (const formName of Object.keys(this.formContainers)) {
        const form = this.formContainers[formName].value;
        const formData = form.getData(true);
        data = merge.clone(data, formData);
      }
      if (this.xData && this.xData.value) {
        data = merge.clone(this.xData.value, data);
      }
      return data;
    }
  },
  extraOptions: {
    forwardStates: {
      xModel: ["model", false],
      xDirty: ["dirty", false],
      xLoading: ["loading", false],
      xValidating: ["validating", false],
      xError: ["error"],
      xDisabled: ["disabled", false]
    },
    defaultClass: "tabBasedForm"
  }
});
